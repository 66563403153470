.victoria_beckham_waitlist {
  background-color: #000;
  /** Black Navigation **/
  .page-header:not(.is-alternate) {
    background: rgba(0, 0, 0, 0.5);
  }
  &.device-pc {
    .page-navigation__menu_text,
    :not(.is-alternate) .page-utilities .page-utilities__signin-text,
    :not(.is-alternate) .page-navigation .level-1 {
      color: #fff;
    }
    :not(.is-alternate) {
      .header-inner .page-branding {
        .page-branding__logo {
          background-size: auto auto;
          width: 187px;
          height: 26px;
          background: url('/media/boutiques/little-black-primer/images/estee_site_branding_sprites.png') 0 -1598px no-repeat;
        }
        .trust-mark-elc-nodeblock .trust_mark_header .trust_mark_header__link {
          background-image: url('/media/images/global/trust_mark_whilte.png');
        }
      }
      .page-utilities {
        .page-utilities__cart-icon {
          background-image: url('/media/images/background-images/background-image1.png');
          background-size: 24px 22px;
          width: 24px;
          height: 22px;
        }
        .page-utilities__search-icon {
          background-image: url('/media/images/background-images/background-image2.png');
          background-position: 0 0;
          width: 24px;
          height: 24px;
        }
      }
    }
    .page-navigation__menu-icon {
      background-size: auto auto;
      background-position: 0 -3723px;
      height: 14px;
      width: 21px;
      background-image: url('/media/boutiques/little-black-primer/images/estee_site_branding_sprites.png');
      background-repeat: no-repeat;
    }
    .page-navigation__menu_text,
    .device-pc :not(.is-alternate) .page-utilities .page-utilities__signin-text,
    .device-pc :not(.is-alternate) .page-navigation .level-1 {
      color: #fff;
    }
  }
  /** End Black Navigation **/
  .signup-tout-wrapper {
    .signup-tout-content {
      .subtitle {
        width: 141%;
        font-size: 12px;
        letter-spacing: 1.5px;
      }
      #email-signup__form {
        margin-left: 47%;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 650px;
      }
      #email-signup__form {
        input.form-text {
          width: 300px;
          border: 1px solid #fff;
          color: #fff;
          background-color: #000;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #fff;
            background-color: #000;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #fff;
            background-color: #000;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #fff;
            background-color: #000;
          }
          :-moz-placeholder {
            /* Firefox 18- */
            color: #fff;
            background-color: #000;
          }
        }
      }
      .thank_you_text {
        font-family: 'AkzidenzGrotesk W1G';
        font-size: 14px;
        display: block;
        margin-left: 10%;
        margin-top: 30px;
        margin-bottom: 20px;
        color: #fff;
        text-align: center;
        width: 141%;
      }
    }
    .footer-social-links {
      margin-left: 66%;
      width: 300px;
    }
  }
  .form-submit {
    background: #000 none repeat scroll 0% 0%;
    color: #fff;
    border: 1px #fff solid;
  }
  .error {
    color: #fff;
    margin: 10px auto;
    text-align: center;
    width: 166%;
  }
}
