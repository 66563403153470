// Live chat overlay
.prechat-form-container {
  margin: 0;
  .beauty-advice-btn {
    margin-top: 10px;
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
  }
  h4 {
    margin: 0;
  }
  .prechat-messaging-area p {
    margin: 0;
  }
}

#colorbox.colorbox__quickshop {
  .quickshop {
    min-height: 596px;
    padding-bottom: 0px;
    @media #{$medium-only} {
      padding-bottom: 20px;
    }
  }
  .quickshop__view-full {
    bottom: 11px;
  }
  .quickshop__tab-container {
    overflow-y: auto;
    padding-right: 10px;
  }
}

.address-select-selectBox-dropdown-menu {
  width: 405px !important;
}

#cd_container {
  .cd_section#cd_section-4 {
    .cd_section_content {
      #cd-tips_wrapper {
        width: 460px;
      }
    }
  }
}

#colorbox {
  #cboxContent {
    .quickshop {
      width: 945px;
      @media #{$medium-only} {
        width: 680px;
      }
      .quickshop__description {
        .quickshop__description-section {
          .quickshop__personal {
            float: left;
            a.quickshop__wishlist {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

#envy_container {
  .landing.envy_section {
    .parallax {
      #envy_section-2_parallax {
        height: 2120px;
      }
    }
  }
}

.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    @media #{$medium-up} {
      width: 70px;
      height: 70px;
      background-size: contain;
    }
    @media #{$xlarge-up} {
      width: 80px;
      height: 80px;
    }
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -22px;
      background-image: url('/media/images/global/lo3.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
      @media #{$medium-up} {
        width: 65px;
        height: 65px;
        background-size: contain;
      }
      @media #{$xlarge-up} {
        width: 70px;
        height: 80px;
      }
    }
  }
}

.page-branding__logo {
  float: right;
}

.sku-brief-editorial .js-add-to-cart {
  padding: 0 15px;
}

.section-re-nutriv,
.brand-renutriv,
#beauty_feed {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3-white.png');
      }
    }
  }
}

.is-alternate {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3.png');
      }
    }
  }
}

.search-page {
  .search-help__content {
    p a.contact-us-link {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.page-sticky-footer {
  .special-offers-tray {
    .special-offer {
      .button {
        @media #{$medium-up} {
          bottom: 2%;
        }
      }
    }
  }
  .page-sticky-footer__left {
    .node-elc-nodeblock {
      border-left: none;
    }
    .special-offers-drawer {
      text-transform: uppercase;
    }
  }
}

.page-wrapper.en_AU #wrapper #innerwrapper {
  width: auto !important;
}

.cssProductsSense .cssOver .cssBtn button span a {
  font-family: 'Akzidenz Bold';
  font-size: 15px;
}

.cssProductsSense .cssOver .cssBtn button:hover span a {
  color: #fff;
}

.mpp-compare__container .mpp-compare-vba {
  display: none;
}

#cd_container .q-and-a_popup_wrapper {
  .popup_container_wrapper {
    p.answer span {
      overflow: auto;
      height: 110px;
    }
  }
}

#rn_container #renutriv_section-6 .content .li4 {
  left: 148px !important;
}

.item_info {
  .name {
    margin-bottom: 10px;
    a {
      font-size: 22px;
      font-family: 'Optima Display Roman';
    }
  }
  .subhead a {
    font-size: 16px;
    font-family: 'Optima Display Roman';
  }
  .price {
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
  }
  .add_button {
    background: none;
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
    letter-spacing: 0.15em;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    color: #040a2b;
    text-decoration: none;
  }
}

.section-email-promotions {
  margin-bottom: 0px;
  padding-left: 0px;
  .sms-mobile {
    padding-left: 0px;
    margin-top: 0px;
    width: 100%;
  }
}

#colorbox.colorbox__quickshop {
  .quickshop__image-container {
    width: 420px;
    height: 578px;
    @media #{$medium-only} {
      width: 300px;
      height: auto;
    }
  }
}

#holiday-2015_container {
  .mpp__product-grid {
    .mpp__product:nth-child(3n + 1) {
      clear: left !important;
    }
    .product_brief__headers {
      h3,
      h4,
      h5 {
        font-weight: normal;
      }
    }
    .product_brief__misc-flag {
      font-size: 12px;
      letter-spacing: 0.15em;
      margin-bottom: 3px;
    }
    .product_brief__header {
      font-family: 'Optima Display Roman';
      font-size: 22px;
    }
    .product_brief__sub-header {
      margin-top: 2px;
    }
  }
}

.parallax__container {
  background-size: 100%;
}

.chat-parallax__content {
  max-width: 1920px;
}

.address-form__fieldset .address-form__address1-container.full {
  padding-left: 0;
}

.address-form__fieldset .address-form__address2-container.full {
  padding-left: 0;
}

.nodeblock-signup-form.promotion-signup-form {
  background: #f4f4f4;
  padding: 60px 60px 30px;
  width: 900px;
  margin: 0 auto 70px auto;
  h3 {
    font-size: 60px;
    text-align: center;
  }
  .promo-signup__terms {
    margin-top: 20px;
  }
  .email_signup__header {
    margin: 0 0 20px;
    font-family: 'OptimaDisplayLight';
    text-align: center;
    letter-spacing: -0.02em;
    line-height: 44px;
  }
  .submit_body {
    padding: 0px 100px;
  }
  .email-signup__section {
    width: 75%;
    margin: 30px auto 0;
  }
  .singup_form_footer {
    text-align: center;
  }
  .singup_form_social {
    width: 180px;
    margin: 0 auto;
    .footer-social-links {
      .facebook {
        background-position: 0 -1104px;
      }
      .twitter {
        background-position: 0 -1374px;
      }
      .youtube {
        background-position: 0 -1465px;
      }
      .pinterest {
        background-position: 0 -1288px;
      }
      .instagram {
        background-position: 0 -1195px;
      }
      .facebook:hover {
        background-position: 0 -1104px;
      }
      .twitter:hover {
        background-position: 0 -1374px;
      }
      .youtube:hover {
        background-position: 0 -1465px;
      }
      .pinterest:hover {
        background-position: 0 -1288px;
      }
      .instagram:hover {
        background-position: 0 -1195px;
      }
    }
  }
  .email-signup__message {
    padding-top: 0px;
    .email-signup__error {
      padding-top: 18px;
    }
    .email-signup-promo__success {
      a.cta {
        width: 100%;
      }
      a.link--bold {
        text-align: center;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 799px) {
  .nodeblock-signup-form.promotion-signup-form {
    background: #f4f4f4;
    padding: 5% 5%;
    width: 100%;
    margin: 0 auto 70px auto;
    h3 {
      font-size: 40px;
    }
  }
}

.offer__kit__formatter--gwp_sp17 {
  .offer__kit__button__add,
  .offer__kit__button__oos {
    width: 65%;
    height: 55%;
    line-height: 44px;
  }
  .button--light {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) {
  .offer__kit__formatter--gwp_sp17 {
    .offer__kit__step--1 {
      .offer__kit__option--01 {
        padding: 5px 0 0px 10px;
      }
    }
  }
}

.offer__kit__formatter--gwp_sp17 {
  .offer__kit__option {
    width: 33%;
  }
}

@media only screen and (min-width: 737px) {
  .offer__kit__formatter--gwp_sp17 {
    .offer__kit__details {
      padding: 8px 0 0 0;
      text-align: center;
    }
  }
}

.offer__kit__formatter--gwp_sp17 {
  .offer__kit__option {
    span.label.offer__kit__option__label {
      padding: 0;
      font-size: 25px;
      text-align: center;
    }
    input[type='checkbox'] ~ label:before,
    input[type='checkbox'] ~ .label:before {
      width: 20px;
      height: 20px;
      margin-top: 3px;
    }
    input[type='checkbox'] ~ label:after,
    input[type='checkbox'] ~ .label:after {
      width: 20px;
      height: 20px;
      margin-top: 3px;
    }
  }
  .offer__kit__option--01 {
    width: 40%;
  }
  .offer__kit__option--02 {
    width: 28%;
  }
  .offer__kit__option--03 {
    width: 32%;
    padding: 5px 0 0 12px;
  }
  .offer__kit__step--2 {
    .offer__kit__option--02 {
      width: 42%;
      label .offer__kit__option__label {
        display: inline-block;
        padding: 0 25px;
      }
    }
    .offer__kit__option--01 {
      input[type='checkbox'] ~ .label {
        padding: 0;
      }
    }
  }
  .offer__kit__button__add {
    background: #040a2b;
    color: white;
  }
}

.viewcart {
  .wp-messages {
    color: red;
  }
  .wp-messages a {
    color: red;
  }
}

body.section-victoria-beckham {
  #colorbox.colorbox__quickshop {
    #cboxContent {
      background: black;
    }
  }
}

.product-full {
  .product-full {
    &__price-installment {
      .afterpay-content,
      .afterpay-content-mb {
        display: none;
      }
      .afterpay_logo {
        width: 100px;
        padding-bottom: 5px;
        background: url('/media/export/cms/afterpay/ap-logo.png') no-repeat;
        height: 25px;
        display: inline-block;
        background-size: 100px;
        vertical-align: middle;
      }
      a {
        margin-left: 30px;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;
      }
    }
    &__price-size-select-container {
      margin-bottom: 11px;
    }
  }
}

@media (min-width: 641px) {
  .pc-hidden {
    display: none;
  }
}

// 2018 Creative refresh
.header-items {
  @media #{$xlarge-up} {
    min-height: 65px;
    padding: 5px 0;
    position: relative;
  }
  .page-branding[style] {
    @media #{$xlarge-up} {
      top: 0 !important;
    }
  }
  .trust_mark_header {
    &__link {
      @media #{$xlarge-up} {
        margin-top: 1px;
      }
    }
  }
  .page-utilities {
    .user-greeting {
      text-decoration: underline;
    }
    .user-login-state {
      .user-greeting {
        text-decoration: underline;
      }
    }
    .utility-nav__account {
      @media #{$medium-up} {
        margin-top: -5px;
        left: -20px;
      }
      @media #{$xlarge-up} {
        left: -10px;
      }
    }
    &__search-icon {
      margin-right: 2px;
      margin-left: -2px;
    }
    .utility-nav__cart {
      @media #{$medium-up} {
        left: 30px;
      }
      @media #{$xlarge-up} {
        left: 50px;
      }
      &-button {
        @media #{$medium-up} {
          border-#{$ldirection}: none;
        }
        @media #{$xlarge-up} {
          border-#{$ldirection}: 1px solid $color-light-gray;
        }
      }
    }
  }
}

.page-navigation {
  @media #{$xlarge-up} {
    margin-top: -20px;
  }
  &__menu {
    @media #{$xlarge-up} {
      padding-bottom: 10px;
    }
  }
  &__menu .menu-ref {
    @media #{$xlarge-up} {
      padding-#{$ldirection}: 106px;
    }
  }
  .menu-ref ~ .menu-ref {
    @media #{$xlarge-up} {
      padding: 0 0.8em;
    }
  }
}

.page-branding__logo {
  @media #{$xlarge-up} {
    margin-#{$ldirection}: 8px;
    margin-top: 1px;
  }
  .is-sticky &,
  .is-alternate &,
  .gnav-active .is-alternate & {
    @media #{$xlarge-up} {
      margin-#{$ldirection}: 8px;
      margin-top: 1px;
    }
  }
  .page-utilities {
    .user-login-state {
      .user-greeting {
        text-decoration: underline;
      }
    }
  }
  .page-utilities {
    .user-greeting {
      text-decoration: underline;
    }
  }
}

.sample__mini--bar {
  @media #{$medium-down} {
    min-height: 400px;
  }
}

.page-wrapper {
  .mpp_module_wrapper,
  .hero-block {
    margin-top: 0px;
  }
}

.product-vto__youcam-module-container {
  @media #{$medium-up} {
    z-index: 99;
  }
}

.product-vto {
  .cta-vto {
    @media #{$medium-up} {
      z-index: 98;
    }
  }
}

.account-favorites {
  &__list {
    > li {
      padding-bottom: 65px;
      position: relative;
      .sku-brief-editorial__bottom {
        position: absolute;
        bottom: 0;
        #{$ldirection}: 50%;
        margin-#{$ldirection}: -65px;
      }
    }
  }
}

.page-footer {
  z-index: 99;
}

.contact-us-page {
  .address-form {
    .form-item {
      @media #{$medium-up} {
        width: 50%;
        float: #{$ldirection};
        input[type='text'],
        input[type='email'],
        .selectbox {
          width: 100%;
          display: block;
        }
        &.full {
          width: 100%;
          clear: both;
          float: none;
        }
        &.left {
          width: 48%;
          margin-right: 2%;
        }
      }
    }
  }
  .contact-form {
    &__order-number {
      color: $color-navy;
    }
  }
}

.mpp {
  &__product-grid {
    .product_brief {
      &__headers {
        min-height: 100px;
        height: auto;
      }
    }
  }
}

.mpp_module_wrapper {
  &.multi_use_mpp_v1 {
    .module_block__hero {
      min-height: 100%;
    }
  }
}

.multi_use_homepage_v1 {
  .video_content_v1 {
    .video_content_cta_outer {
      margin-top: 0;
    }
  }
}

.email-signup {
  input[type='tel'] {
    width: 100%;
  }
}

#zero_dollar_overlay {
  h3 {
    text-align: center;
  }
  .popup_contents {
    text-align: center;
    .checkout-buttons,
    .shopping-buttons {
      display: inline-block;
      a {
        background-color: $color-navy;
        color: $color-white;
      }
    }
  }
}
.spp-engraving {
  .engraving-cat-571 {
    .engraving-form-choice-43 {
      .engrave-placement {
        bottom: 240px;
      }
    }
  }
  .engraving-cat-28371 {
    .engraving-form-choice-43 {
      .engrave-placement {
        bottom: 207px;
      }
    }
  }
}
