.brand-aerin {
  #colorbox {
    .pre-order-container {
      .pre-order_status_display {
        float: $ldirection;
        width: 100%;
      }
    }
  }
}

.checkout {
  .left.checkout__content {
    .preorder-panel {
      margin-top: 7%;
      .viewcart-preorderheader {
        .checkout__panel-title {
          font-weight: bold;
        }
      }
      &__subhead,
      .details-link {
        color: $color-blue--estee_edit;
      }
      .details-link {
        text-decoration: underline;
      }
      .checkout__panel-title {
        font-weight: bold;
      }
      &__viewcart-text {
        width: 345px;
        margin-top: 10%;
      }
      .cart-item {
        &__desc {
          width: 24%;
          margin-#{$rdirection}: 2%;
          &-info {
            word-wrap: break-word;
          }
        }
        &__price {
          width: 13%;
        }
        &__total {
          width: 18%;
        }
      }
    }
    #preorder-summary-panel {
      .order-summary {
        &-panel__title,
        &__subtotal-label {
          text-align: $ldirection;
        }
        &__shipping-label {
          float: $ldirection;
          width: 30%;
          margin: 0;
          line-height: inherit;
          text-align: $ldirection;
        }
        &__shipping-value {
          float: $rdirection;
          margin: 0;
          line-height: inherit;
        }
        &__shipping-content {
          position: inherit;
          float: $ldirection;
          width: 40%;
          margin: 0;
        }
      }
    }
  }
}

#index {
  .right.checkout__sidebar {
    .preorder-panel {
      margin-top: 10px;
      .checkout__panel-title {
        display: inline-block;
      }
      .preorder-text__content {
        float: $ldirection;
        position: absolute;
        #{$ldirection}: 25%;
        bottom: 15%;
      }
    }
    .preorder-panel__order-summary {
      .order-summary {
        &__subtotal-label,
        &__shipping-label,
        &__total-label {
          text-align: $ldirection;
        }
      }
    }
  }
  .checkout {
    .pre-order-button-disabled {
      color: $color-white;
      border: 1px solid $color-white;
      background: $color-light-gray;
      pointer-events: none;
    }
  }
}

.bottom-viewcart-buttons {
  .preorder-paypal-info {
    text-align: $ldirection;
    margin-top: 5%;
  }
}

.payment-panel {
  .securepay-preorder {
    color: $color-red;
    margin-bottom: 15px;
    a {
      color: $color-blue--estee_edit;
      text-decoration: underline;
    }
  }
}

.preorder-confirmation-panel {
  .confirmation-panel {
    &__email-coming {
      width: 63%;
      margin-top: 10px;
      margin-#{$ldirection}: 20px;
    }
    &__confirm-text {
      margin-#{$ldirection}: 20px;
    }
  }
  .preorder-text__content {
    @include swap_direction(margin, 10px 0 0 20px);
    width: 63%;
    a {
      color: $color-blue--estee_edit;
      text-decoration: underline;
      text-transform: capitalize;
    }
  }
}

.order-history-page {
  .order-status-table {
    .sku-brief-editorial__preorder {
      font-weight: inherit;
      text-transform: inherit;
      color: $color-red;
      margin: 10px 0;
    }
  }
  .preorder-history__subheader {
    margin: 1% 0 4% 0;
    font-size: 17px;
    text-decoration: underline;
  }
}

.order-details-page {
  &__preorder {
    margin: 10px 0;
    display: inline-block;
  }
}

.preorder-gift__head-content {
  .gift-options__title {
    float: $ldirection;
    width: 20%;
  }
  .preorder-gift__text {
    width: 60%;
    float: $ldirection;
    margin: 18px 0 15px;
  }
}

.preorder-payment {
  .payment-form {
    .preorder-payment {
      &__save-payment {
        &:before {
          content: '';
        }
      }
    }
  }
}

.preorder-payment-overlay {
  #cboxLoadedContent {
    margin: 35px 0 20px;
  }
}

.pre-order__info,
.register_preorder_msg,
.cart-confirm__preorder {
  color: $color-red;
}

.preorder-payment_container {
  display: flex;
  flex-wrap: wrap;
  .payment-option {
    &:nth-of-type(1) {
      order: 1;
    }
    &:nth-of-type(2) {
      order: 2;
    }
    &:nth-of-type(3) {
      order: 5;
    }
  }
  .pre-order__info {
    &:nth-of-type(1) {
      order: 3;
    }
    &:nth-of-type(2) {
      order: 4;
    }
    &:nth-of-type(3) {
      order: 6;
    }
  }
}

.preorder-spp-container {
  .review-snippet,
  .pr-review-display {
    pointer-events: none;
    opacity: 0.6;
  }
  .product-full__price-installment {
    display: none;
  }
}

.pre-order-button-disabled {
  color: $color-white;
  border: 1px solid $color-white;
  background: $color-light-gray;
  pointer-events: none;
}

.wishlist-page,
.account-wishlist {
  .sku-brief-editorial {
    &__preorder {
      text-transform: inherit;
      color: $color-red;
      font-weight: inherit;
      margin: 5px 0;
    }
  }
}

.preorder-details-page {
  .payment-form {
    display: none;
  }
}
