.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

input[type='tel'] {
  color: $color-black;
}

input[type='radio'] ~ label,
input[type='radio'] ~ .label {
  margin-left: 1px;
}

#cboxLoadedContent {
  .sign-in-component {
    overflow-y: auto;
    overflow-x: hidden;
    height: 475px;
  }
  .email_popover {
    height: auto !important;
  }
}

.ui-widget-content {
  height: 12em;
  overflow-y: auto;
  width: 38em;
}

html[data-useragent*='MSIE 8.0'] .ie-8 .page-navigation {
  width: 100%;
  margin-left: 0px;
}

.foundation-finder-page {
  .ff-results__product {
    .product_brief__image-container,
    .product_brief__image,
    .sku-brief-editorial__image {
      width: 100%;
    }
  }
}

.footer-forms-location-sms {
  &-email-signup-elc-nodeblock {
    .footer-signup-email {
      width: 50%;
      .email-signup-email {
        width: 49%;
        display: inline-block;
      }
      .email-signup-mobile {
        width: 49%;
        display: inline-block;
        vertical-align: top;
      }
    }
    input[type='checkbox'] ~ label {
      float: left;
      padding-top: 0;
      margin-top: 10px;
    }
  }
}

.customer-service {
  &--landing {
    #customer_service_chat_1 {
      margin-left: 115px;
    }
  }
  &--faqs {
    #customer_service_chat_2 {
      display: none;
    }
  }
}

[class*='-double-wear'] {
  .product-full {
    &__skintype-select-container {
      display: none;
    }
  }
}

.limited-edition-offers {
  .special-offer-row {
    .special-offer-eyebrow {
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.colorbox {
  &__quickshop {
    #cboxWrapper {
      #cboxContent,
      #cboxLoadedContent {
        height: auto !important;
        .quickshop {
          &__description {
            padding: 38px 32px 10px 0;
          }
        }
      }
    }
  }
}

.checkout_banner {
  img {
    max-width: 726px;
  }
}

#signin {
  .page-header {
    position: relative;
  }
}

.sidebar-page {
  margin-top: -60px;
}

.checkout {
  overflow: initial;
}
