.colorbox__wishlist-confirm #cboxLoadedContent {
  height: auto !important;
  margin: 0;
}

.colorbox--social-share #cboxLoadedContent {
  height: auto !important;
}

.product__inventory_status {
  margin-top: 15px;
  li {
    display: inline-block;
    &.product__notify_status {
      padding-left: 22px;
    }
  }
}

.tiny-waitlist-overlay {
  padding: 20px 40px 0 40px;
  .email_input {
    input[type='text'] {
      width: 230px;
      margin-right: 20px;
      margin-bottom: 0;
      text-align: start;
      -webkit-box-shadow: inset 0 0 5px #cccccc;
      box-shadow: inset 0 0 5px #cccccc;
      padding: 6px 10px 5px 10px;
      border-color: #cccccc;
      color: #cccccc;
      height: 33px;
      font-size: 12px;
      font-family: 'AkzidenzGrotesk W1G';
      text-transform: lowercase;
    }
    .error {
      border-color: red !important;
    }
  }
  .button {
    height: 32px;
    line-height: 32px;
  }
  .waitlist_heading,
  .waitlist_thankyou_heading {
    font-size: 40px;
  }
  .waitlist_message,
  .waitlist_thankyou_message {
    text-transform: uppercase;
  }
}

.spp {
  .discover_more__product:nth-child(2) {
    margin: 0 30px;
  }
}

.spp-product__reviews {
  height: auto;
}
